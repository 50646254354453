.collections--row {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
}

.collection--card {
  width: 425px;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  &-text {
    padding: .75em;
  }
}
