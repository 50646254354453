// This file contains all application-wide Sass variables.

// Colors
$primary-color: #748479;
$bg-dark-gray: #42474C;

// Layout
$section-spacing: 0 0.66667em;

// Regular font family
// @type List
$text-font-stack: 'Montserrat', sans-serif;

// Code (monospace) font family
// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

// Copy text color
// @type Color
$text-color: rgb(34, 34, 34) !default;

// Main brand color
// @type Color
$brand-color: #9DA890;

// Light grey
// @type Color
$light-grey: rgb(237, 237, 237) !default;

// Medium grey
// @type Color
$mid-grey: rgb(153, 153, 153) !default;

// Dark grey
// @type Color
$dark-grey: rgb(68, 68, 68) !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;

// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN
//   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
