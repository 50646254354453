$breakpoints: (
  'small'  : ( min-width:  700px ),
  'medium' : ( min-width:  1000px ),
  'large'  : ( min-width: 1400px )
);

@mixin mq($name) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}
