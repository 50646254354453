.homepage-body {
    overflow: hidden;
}
.homepage--hero-section {
  @include hero-img(url('/img/hero.jpg'));

  > h1 {
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    font-size: 45px;
    text-align: center;
    font-weight: 400;
    @include mq(small) {
      font-size: 85px;
    }
  }

  > h2 {
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: 400
  }

  > a {
    padding: .55em 1.15em;
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    transition: color 170ms ease-in-out, border-color 170ms ease-in-out;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: center;
    &:hover {
      color: #181818;
      background: #fff;
    }
  }
}
