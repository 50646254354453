.global--logo {
  color: #333;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  h1 {
    line-height: 0.2;
    font-weight: 600;
    font-size: 24px;
  }
}

.global--main-nav {
  width: 100%;
  height: 75px;
  padding: 0 1.5em;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 100;
  transition: 0.35s ease-in;

  &-left {
    img {
      margin-top: 5px;
      width: 250px;
    }
  }

  &-items {
    display: none;
    @include mq(medium) {
      display: flex;
    }
  }

  &-item {
    margin-top: 1em;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-size: 0.85rem;
    color: hsla(209, 17%, 24%, .5);
    @include mq (medium) {
      display: block;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0.75em;
      padding-right: 0.75em;
    }

    &:hover {
      color: #adadad;
      text-decoration: none;
    }
  }
}
// When you click on hamburger icon, .mobile-open class is added to <nav>

.mobile-open {
  position: absolute;
  width: 100%;
  height: 100vh;
  right: 0;
  top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  transition: 1s ease-in-out;
}

#hamburger {
  width: 28px;
  position: relative;
  top: -3px;
  cursor: pointer;
  @include mq(medium) {
    display: none;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: 1s ease-in-out;
}

.fade-leave {}

.fade-leave-active {
  transition: 1s ease-in-out;
  opacity: 0;
}
