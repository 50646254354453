.about--content {
  display: flex;

  > div {
    flex: 1;
    p {
      margin-bottom: 40px;
    }
  }

  img {
    margin-top: 29px;
  }

  &-wrapper {
    padding: 0 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &-spacing {
    margin-top: 50px;
    h2 {
      margin-top: 0;
    }
  }
}
