.bullet-flex {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @include mq(small) {
    margin-left: 0;
    flex-direction: row;
  }

  ul {
    @include mq(small) {
      padding-left: 20px;
    }
  }

  li {
    margin: 0.5em 0;
    list-style-type: circle;
  }
}

.testimonial {
  margin: 2em 0;
  padding: 2em 0;
  background: $primary-color;

  p {
    color: #fff;
    font-size: 1.05rem;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-top: 25px;
      margin-bottom: 25px;
      width: 125px;
      border-radius: 50%;
    }
    p {
      margin-top: 0;
    }
  }
}
