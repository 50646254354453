html {
  box-sizing: border-box;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
  margin: 0;
  color: #333e48;
  -webkit-text-size-adjust: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.33333rem;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
// Basic styles for links

a {
  color: #46B1C9;
  text-decoration: none;
  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

button,
input,
optgroup,
select,
textarea {
  font: normal 125% / 1.4 $text-font-stack;
}

main {
  h2 {
    margin-top: 35px;
    font-size: 1.95rem;
    font-weight: 600;
    line-height: 1.22;
  }

  h3 {
    margin-top: 35px;
    font-size: 1.35rem;
    line-height: 1.22;
  }

  p {
    margin-top: 29px;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -.003em;
  }
}

section > * {
  margin-left: 0.66667em;
  margin-right: 0.66667em;
}

.container,
.wrapper {
  padding: 0 calc(50% - 480px);
}

.section-spacing {
  margin-top: 3.25em;
  margin-bottom: 3.25em;
}

.header-spacing {
  margin-bottom: 2.5em;
}

img {
  max-width: 100%;
  display: block;
}

.row-full {
  margin: 0;
}

.page-title {
  font-size: 4.5rem;
  line-height: 2;
  text-align: center;
}

.sub-header {
  font-size: 2.5rem;
  line-height: 1.5;
  text-align: center;
}

.article-intro {
  padding: 7em calc(50% - 600px) 0;
  text-align: center;
  @include mq (small) {
    padding: 8em calc(50% - 600px) 0;
  }

  > h1 {
    margin-bottom: 0.5em;
    font-size: 1.85rem;
    font-weight: 400;
    line-height: 1;
    @include mq (small) {
      font-size: 2.5rem;
    }
  }

  > h3 {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1;
    @include mq (small) {
      font-size: 1.45rem;
    }
  }

  > img {
    margin: 0 auto;
  }
}

.article-img {
  width: 90%;
  margin: 0 auto;
  @include mq(medium) {
    width: 60%;
  }
}

.text-wrapper {
  max-width: 740px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.paragraph-padding {
  padding: 0 2em;
}
