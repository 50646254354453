@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'utilities/variables',
  'utilities/functions',
  'utilities/media-queries',
  'utilities/responsive-embed',
  'utilities/mixins';

// 3. Base stuff
@import
  'base/base',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/main-navigation',
  'layout/footer';

// 5. Components
@import
  'components/buttons',
  'components/ui-patterns';

// 6. Page-specific styles
@import
  'pages/homepage',
  'pages/collections',
  'pages/about',
  'pages/work-together',
  'pages/gallery';
