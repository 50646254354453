// Basic typography style for copy text

.ctct-custom-form,
body {
    font-family: $text-font-stack;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Montserrat', sans-serif;
}

a,
li,
p,
small {
    font-family: 'Arvo', serif;
}
