$item-width: 375px;

.wrapper {
	padding: 2.5em calc(50% - 600px);
}

.grid {
	&-item {
		width: $item-width;
		margin: 5px !important;
	}
}

.grid-sizer {
	width: $item-width;
}

.gallery {
	text-align: center;

	p {
		font-size: 1rem;
		margin-bottom: 1.5em;
	}

	.filter-button-group {
		margin-bottom: 2.5em;
	}

	&--filter {
		margin-left: 0.75em;
		margin-right: 0.75em;
		font-size: 0.85em;
		color: #666666;
		border: none;
		background: #fff;
		transition: all 0.3s;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}

	&--images {
		margin-top: 1em;
	}
}

.is-checked {
	padding: 0.40em 0.65em;
	color: #fff;
	background: $primary-color;
	border-radius: 2px;
}
