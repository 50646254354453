.global--footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #fff;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-copyright {
    margin-top: .75em;
    text-align: center;

    > p {
      font-size: 0.65rem;
      margin: 0;
      line-height: 1.5;
      text-align: center;
    }
  }
}

.article--footer {
  margin-top: 50px;
  margin-bottom: 25px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-social {
    display: flex;
    justify-content: center;

    img {
      width: 28px;
      height: 28px;
      margin-left: 0.75em;
      margin-right: 0.75em;
    }
  }

  &-copyright {
    margin-top: .5em;
    text-align: center;
    margin-bottom: .5em;

    > small {
      font-size: 0.65rem;
      margin: 0;
      line-height: 1.5;
    }
  }
}
